;(function() {    

    if( $( '#consumer-activations-table' ).length ) {
        $('#consumer-activations-table').dataTable( {
            scrollY: 400
        } );
    }

    if( $( '#all-consumers-table' ).length ) {
        $( '#all-consumers-table' ).dataTable( {
            scrollY: 400
        } );
    }

    if( $( '#start-date' ).length ) {
        $( '#start-date' ).pickadate( {
            format: 'mm/dd/yyyy'
        } );
    }

    if( $( '#end-date' ).length ) {
        $( '#end-date' ).pickadate( {
            format: 'mm/dd/yyyy'
        } );
    }

}() );